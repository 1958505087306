function Hamburger({ onClick }) {
  return (
    <button
      style={{ top: 0, right: 20 }}
      onClick={() => onClick(true)}
      className="md:hidden flex absolute  p-8 flex-col "
    >
      <span className="bg-black rounded" style={{ width: 22, height: 2 }} />
      <span
        className="bg-black rounded"
        style={{ width: 22, height: 2, marginBottom: 6, marginTop: 6 }}
      />
      <span className="bg-black rounded" style={{ width: 22, height: 2 }} />
    </button>
  );
}

export default Hamburger;
