import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Collection from "./pages/Viewer";
import { Symfoni } from "./hardhat/SymfoniContext";


function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });

  function renderApp() {
    return (
      <>
        <Switch>
          <Route exact path="/collection">
            <Symfoni autoInit={true} showLoading={false}>
              <BaseLayout>
                <Collection />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="*">
            <Symfoni autoInit={true} showLoading={false}>
              <BaseLayout>
                <Home mint={() => console.log("mi click")} />
              </BaseLayout>
            </Symfoni>
          </Route>
        </Switch>
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
