import React, { useState } from "react";
import Modal from "react-modal";
import banana from "../assets/BBLoad.gif";
import cs from "classnames";
import { useEVM } from "../hooks/EVMhook";
import AssetCardRow from "./AssetCardRow";
import shop from "../assets/shop.svg"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(40, 40,40, 0.8)",
  },
};

export default function MModal({ closeModal, data = {}, initialState, offer }) {
  const [loaded, setLoaded] = useState(false);
  const { mintService, address } = useEVM();
  const [wallet, setWallet] = useState("");
  const [loading, setloading] = useState(false);

  async function handleSend() {
    setloading(true);
    const trans = await mintService?.instance.transferFrom(
      address,
      wallet,
      data.id
    );
    mintService.instance.provider
      .waitForTransaction(trans.hash)
      .then(async () => {
        setloading(false);
      });
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        isOpen={true}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="h-full w-full text-white px-4 py-4 border-0 rounded-lg justify-center items-center shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex w-full gap-4">
            <div className="flex">
              {!loaded && (
                <img
                  style={{ maxHeight: "20rem" }}
                  alt="preview"
                  className={cs("animate-pulse w-full max-w-sm ")}
                  src={banana}
                />
              )}

              <img
                onLoad={() => {
                  setLoaded(true);
                }}
                alt="preview"
                className={cs("w-80 cursor-pointer h-80", {
                  hidden: !loaded,
                })}
                onClick={  () => {
                    window.open(
                        data.image,
                        "_blank"
                      );
                  }}
                src={data.image}
              />
            </div>
            <div className="flex w-80  flex-col">
              <div className="flex items-center">
              <span
                style={{ fontFamily: "Poppins" }}
                className="text-black text-center w-full mt-3 text-lg mb-3 font-semibold"
              >
                {data.id !== null && <>BB #{data?.id}</>}
              </span>
              <a                    rel="noreferrer"
     target="_blank"
className="h-8 w-8" href={`https://www.avaxapes.com/bear/${data?.id}`}>
          <img src={shop} alt="shop" />
          </a>
            </div>
              <div className="flex gap-4 flex-col">
                <AssetCardRow
                  title="BEAR"
                  cols={4}
                  name={
                    data?.attributes?.find((a) => a?.trait_type === "Bear")
                      ?.value || "None"
                  }
                  value={
                    data?.attributes?.find((a) => a?.trait_type === "Bear")
                      ?.points || 0
                  }
                />
                <AssetCardRow
                  title="HEAD"
                  name={
                    data?.attributes?.find((a) => a?.trait_type === "Head")
                      ?.value || "None"
                  }
                  cols={4}
                  value={
                    data?.attributes?.find((a) => a?.trait_type === "Head")
                      ?.points || 0
                  }
                />
                <AssetCardRow
                  title="EYES"
                  cols={3}
                  name={
                    data?.attributes?.find((a) => a?.trait_type === "Eyes")
                      ?.value || "None"
                  }
                  value={
                    data?.attributes?.find((a) => a?.trait_type === "Eyes")
                      ?.points || 0
                  }
                />
                <AssetCardRow
                  title="MOUTH"
                  name={
                    data?.attributes?.find((a) => a?.trait_type === "Mouth")
                      ?.value || "None"
                  }
                  cols={3}
                  value={
                    data?.attributes?.find((a) => a?.trait_type === "Mouth")
                      ?.points || 0
                  }
                />
                <AssetCardRow
                  title="BODY"
                  name={
                    data?.attributes?.find((a) => a?.trait_type === "Body")
                      ?.value || "None"
                  }
                  cols={4}
                  value={
                    data.attributes?.find((a) => a.trait_type === "Body")
                      ?.points || 0
                  }
                />
                <div
                  style={{ fontFamily: "Poppins" }}
                  className="flex text-sm gap-2"
                >
                  <input
                    onChange={(e) => setWallet(e.target.value)}
                    className="border outline-none text-red w-full mt-2 pl-2 border-bluee rounded-lg"
                    placeholder="Enter wallet to transfer Bear"
                  />
                  <button
                    onClick={handleSend}
                    disabled={loading}
                    className="bg-red p-2 mt-2 w-24 rounded-lg text-white"
                  >
                    {loading ? "Loading..." : "Transfer"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
