import detectEthereumProvider from "@metamask/detect-provider";
import { useContext, useEffect, useState } from "react";

// Import auto generated context from Symphony file
import { BridgingBearsContext, BridgingBearsHalloweenContext, BabyBearsContext, WrappedBridgingBearsContext } from "../hardhat/SymfoniContext";

import { chains } from "./chains";
import { config } from "./config";

export function useEVM() {
  const CHAINID = chains[config.chain].chainId;
  const mintService = useContext(BridgingBearsContext);
  const halloweenService = useContext(BridgingBearsHalloweenContext);
  const babyContext = useContext(BabyBearsContext);
  const wrapContext = useContext(WrappedBridgingBearsContext)

  const [isNetwork, setIsNetwork] = useState(false);
  const [address, setAddress] = useState(null);
  const [isMetamask, setIsMetamask] = useState(false);

  const CHAIN_PARAMS = chains[config.chain];

  async function addNetwork() {
    if (isMetamask && isNetwork) {
      console.log("launching metamask");
      return launchMetamask();
    }
    const provider = await detectEthereumProvider();
    if (!provider) return;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [CHAIN_PARAMS],
    });
  }

  async function launchMetamask() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAddress(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }
  }

  useEffect(() => {
    const fetcher = async () => {
      const provider = await detectEthereumProvider();
      console.log("connected to", mintService?.instance?.address);
      setIsNetwork(
        provider?.chainId?.toString().toLowerCase() ===
          CHAINID.toString().toLowerCase()
      );
      setAddress(provider?.selectedAddress);
      setIsMetamask(provider?.isMetaMask);
    };

    fetcher();
  }, [CHAINID, mintService?.instance?.address]);

  return {
    isConnected: address && isNetwork,
    isNetwork,
    mintService,
    isMetamask,
    addNetwork,
    address,
    halloweenService,
    babyContext,
    wrapContext
  };
}
