import cs from "classnames";
import banana from "../assets/BBLoad.gif";
import MModal from "./Modal";
import { useState } from "react";

function AssetCard({ data, index, useName = false, isModal }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  data = { ...data };
  return (
    <div
      onClick={() => (!isModal && !modalOpen ? setModalOpen(true) : null)}
      className={cs("rounded-xl bg-white flex flex-col", {
        "shadow-md p-5 cursor-pointer hover:shadow-2xl": !isModal,
      })}
    >
      {modalOpen && !isModal && (
        <MModal
          data={data}
          closeModal={() => {
            setModalOpen(false);
          }}
        />
      )}

      {!loaded && (
        <img
          style={{ maxHeight: "20rem" }}
          alt="preview"
          className={cs("animate-pulse w-full max-w-sm ")}
          src={banana}
        />
      )}

      <img
        onLoad={() => {
          setLoaded(true);
        }}
        style={{ maxHeight: "20rem" }}
        alt="preview"
        className={cs("max-w-sm cursor-pointer w-full ", {
          hidden: !loaded,
        })}
        src={data.image}
      />

      <div className="flex text-black w-full bg-white rounded-b-lg flex-col">
        <div className="flex items-center justify-between">
          <span
            style={{ fontFamily: "Poppins" }}
            className="text-black text-center w-full mt-3 text-sm font-semibold"
          >
            {data.id !== null && <>BB #{data?.id}</>}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AssetCard;
