import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../assets/Logo.png";
import x from "../assets/components/x.svg";
import cs from "classnames";
import { useEVM } from "../hooks/EVMhook";
// import Wallet from "../assets/components/wallet.svg";
import Hamburger from "../components/Hamburger";
import { ethers } from "ethers";

function Header() {
  const history = useHistory();
  const [rewards, setRewards] = useState(0);

  const {
    isMetamask,
    addNetwork,
    isConnected,
    isNetwork,
    address,
    mintService,
  } = useEVM();

  useEffect(() => {
    mintService?.instance
      ?.getReflectionBalances()
      .then((r) => setRewards(ethers.utils.formatEther(r).substring(0, 7)));
  });

  const [show, setShow] = useState(false);

  function getButton() {
    if (!isMetamask) return "You need Metamask";
    if (isConnected && isNetwork) return address?.substring(0, 7);
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to AVAX";
  }

  function claim() {
    mintService?.instance?.claimRewards();
  }

  return (
    <>
      {show && (
        <div
          className={cs(
            "z-50 bg-white transition-colors fixed duration-500 top-0 bottom-0 flex flex-col left-0 right-0"
          )}
        >
          <button
            onClick={() => setShow(false)}
            className="outline-none z-50 self-end justify-self-end pr-8 pt-8 text-xl"
          >
            <img alt="x" className="w-6 cursor-pointer " src={x} />
          </button>
          <div className="flex pt-20 text-black -mt-10 flex-col justify-start h-full items-center">
            <img
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              alt="logo"
              className="mb-8 cursor-pointer w-64"
              src={logo}
            />
            <span
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              className="font-normal mb-6 cursor-pointer"
            >
              Home
            </span>
            <span
              onClick={() => {
                history.push("/collection");
                setShow(false);
              }}
              className="font-normal mb-6 cursor-pointer"
            >
              Market
            </span>
            <span
              onClick={() => {
                history.push("/collection");
                setShow(false);
              }}
              className="font-normal mb-6 cursor-pointer"
            >
              Collection
            </span>
            <div className="flex gap-3 mb-6 text-black font-semibold items-center">
              <span>Rewards: {parseFloat(rewards).toFixed(3)}</span>
              <button
                onClick={claim}
                className="px-3 rounded-full bg-red text-white "
              >
                Harvest
              </button>
            </div>
            <div
              onClick={() => {
                return isMetamask
                  ? addNetwork()
                  : window.open(
                      "https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche",
                      "_blank"
                    );
              }}
              className="font-normal items-center flex rounded px-2 py-1 border cursor-pointer"
            >
              <div className="w-4 mr-2"></div>
              {getButton()}
            </div>
          </div>
        </div>
      )}
      <header
        className={cs(
          `h-14 w-full bg-white transition-colors duration-1000  flex  z-40`
        )}
      >
        <nav className="font-semibold h-14 -mb-14 md:px-24 text-white flex items-end w-full justify-between ">
          <div className="w-96 hidden md:flex gap-4 font-bold">
            <span
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              className="mr-1 hidden md:flex text-black cursor-pointer"
            >
              Home
            </span>
            <span
              onClick={() => {
                history.push("/collection");
                setShow(false);
              }}
              className="mr-1 hidden md:flex text-black cursor-pointer"
            >
              Market
            </span>
            <span
              onClick={() => {
                history.push("/collection");
                setShow(false);
              }}
              className="mr-1 hidden md:flex text-black cursor-pointer"
            >
              Collection
            </span>
          </div>
          <img className="w-80 " alt="logo" src={logo} />
          <Hamburger onClick={() => setShow(true)} />

          <div className="md:flex gap-5 w-96 hidden items-center md:justify-end">
            <div className="flex gap-3 text-black font-semibold items-center">
              <span>Rewards: {parseFloat(rewards).toFixed(3)}</span>
              <button
                onClick={claim}
                className="px-3 rounded-full bg-red text-white "
              >
                Harvest
              </button>
            </div>
            <div
              onClick={() => {
                return isMetamask
                  ? addNetwork()
                  : window.open(
                      "https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche",
                      "_blank"
                    );
              }}
              className="font-normal items-center text-black flex rounded px-2 py-1 cursor-pointer"
            >
              {getButton()}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
