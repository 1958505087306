import Header from "../layout/Header";
import Twitter from "../assets/socials/twitter.svg";
import Discord from "../assets/socials/discord.svg";

function BaseLayout({ children }) {
  return (
    <div
      style={{ fontFamily: "Poppins" }}
      className="flex text-white h-full flex-col items-center w-full"
    >
      <Header />
      <main
        style={{ fontFamily: "Poppins" }}
        className="text-white h-full w-full"
      >
        {children}
      </main>
      <footer
        style={{ height: 51 }}
        className="bottom-0 bg-white fixed flex justify-between px-24 pb-2 text-black items-center text-2xs right-0 left-0 z-10"
      >
        <span>Copyright&#169; 2021, Bridging Bears</span>
        <div className="flex  z-10 ">
          <a
            className="mx-2 text-black p-1.5 rounded-full"
            target="_blank"
            href="https://twitter.com/BridgingBears"
            rel="noreferrer"
          >
            <img className="w-5" alt="twitter" src={Twitter} />
          </a>
          <a
            className="text-black p-1.5 rounded-full"
            target="_blank"
            href="https://discord.gg/TGazGtXtcn"
            rel="noreferrer"
          >
            <img className="w-5" alt="discord" src={Discord} />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default BaseLayout;
