
import "./paginateStyles.css";

function Viewer() {
  
  return (
    <div className="w-full pb-20 px-5 mt-24 text-xl flex-col md:px-0 flex gap-6 flex-wrap items-center justify-start">
      <div className="flex md:flex-row flex-col justify-between w-full  px-6 max-w-7xl">
        <div className="flex w-full flex-col">
          <span
            style={{ fontFamily: "Poppins" }}
            className="text-2xl self-center text-red font-bold"
          >
            Collections
          </span>
        </div>
      </div>

      <div className="flex flex-wrap text-base gap-5">
        <a href="https://opensea.io/collection/bridging-bears-v2" className="rounded w-44 py-4 text-center flex items-center justify-center bg-red">
          OG Bridging Bears
        </a>
        <a href="https://opensea.io/collection/wrapped-bridging-bears" className="rounded w-44 py-4 text-center flex items-center justify-center bg-red">
          Wrapped Bridging Bears
        </a>
        <a href="https://opensea.io/collection/baby-bears-2" className="rounded w-44 py-4 text-center flex items-center justify-center bg-red">
          Baby Bridging Bears
        </a>
      </div>

    </div>
  );
}

export default Viewer;
