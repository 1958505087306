import { useState } from "react";
import { useEVM } from "../hooks/EVMhook";

import cs from "classnames";

export default function Baby() {
  const [id1, setId1] = useState();
  const [id2, setId2] = useState();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
    const [_error, setShowError] = useState()

  const { babyContext, address } = useEVM();



  async function handleClaimBear() {
    setShowError(null)

    try {
    setShowSuccess(false);
    setShowLoading(true)
    const transaction = await babyContext?.instance?.mint(id1, id2);
    babyContext?.instance?.provider?.waitForTransaction(transaction?.hash).then(() => {
      setShowLoading(false)
      setShowSuccess(true)
    })
    } catch(error) {
      if (error?.code === 4001 && error.message) {
        setShowError(error?.message)
      } else if (error?.data?.message) {
        setShowError(error?.data?.message)

      } else {
        setShowError("Something went wrong. Try again.")

      }
      setShowLoading(false)
    }

  }

  return (
    <div className="flex flex-col border border-red gap-4 rounded p-2">
      <span className="text-sm">
        Enter the Ids of two BB that havent been used to claim a Baby Bear
      </span>
      <div className="flex gap-2">
        <input
            value={id1}
          onChange={(e) => {
            if (parseInt(e.target.value) < 0) {
              return;
            } else {
              setId1(parseInt(e.target.value));
            }
          }}
          type="number"
          placeholder="Enter BB ID 1"
          className="px-2 border border-red rounded"
        />{" "}
        <input
            value={id2}
          onChange={(e) => {
            if (parseInt(e.target.value) < 0) {
              return;
            } else {
              setId2(parseInt(e.target.value));
            }
          }}
          type="number"
          placeholder="Enter BB ID 2"
          className="px-2 border border-red rounded"
        />
      </div>
      <button
        disabled={!id1 || !id2 || id1 === id2}
        onClick={handleClaimBear}
        className={cs("bg-red rounded text-white", {
          "bg-opacity-20": !id1 || !id2 || id1 === id2,
        })}
      >
        Claim Baby Bears
      </button>
      {showLoading && (
        <div className="text-red text-center text-lg animate-pulse my-1">Loading...</div>
      )}
            { _error &&

        _error
      }
      {showSuccess && (
        <a href={"https://opensea.io/"+address} className="text-red text-center text-lg my-1">
          Success! Click here to check your baby bear in Open Sea.
        </a>
      )}
    </div>
  );
}
