/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { providers, Signer, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import { AvaxApes } from "./typechain/AvaxApes";
import { AvaxApes__factory } from "./typechain/factories/AvaxApes__factory";
import BridgingBearsDeployment from "./deployments/mainnet/BridgingBears.json";
import { BridgingBears } from "./typechain/BridgingBears";
import { BridgingBears__factory } from "./typechain/factories/BridgingBears__factory";
import BridgingBearsHalloweenDeployment from "./deployments/mainnet/BridgingBearsHalloween.json";
import { BridgingBearsHalloween } from "./typechain/BridgingBearsHalloween";
import { BridgingBearsHalloween__factory } from "./typechain/factories/BridgingBearsHalloween__factory";
import WrappedBridgingBearsDeployment from "./deployments/mainnet/WrappedBridgingBears.json";
import { WrappedBridgingBears } from "./typechain/WrappedBridgingBears";
import { WrappedBridgingBears__factory } from "./typechain/factories/WrappedBridgingBears__factory";
import BabyBearsDeployment from "./deployments/mainnet/BabyBears.json";
import { BabyBears } from "./typechain/BabyBears";
import { BabyBears__factory } from "./typechain/factories/BabyBears__factory";
import { ERC1155 } from "./typechain/ERC1155";
import { ERC1155__factory } from "./typechain/factories/ERC1155__factory";
import { ERC721 } from "./typechain/ERC721";
import { ERC721__factory } from "./typechain/factories/ERC721__factory";

const emptyContract = {
    instance: undefined,
    factory: undefined
};
const defaultProvider: providers.Provider | undefined = undefined;
export const ProviderContext = React.createContext<[providers.Provider | undefined, React.Dispatch<React.SetStateAction<providers.Provider | undefined>>]>([defaultProvider, () => { }]);
const defaultCurrentAddress: string = "";
export const CurrentAddressContext = React.createContext<[string, React.Dispatch<React.SetStateAction<string>>]>([defaultCurrentAddress, () => { }]);
const defaultSigner: Signer | undefined = undefined;
export const SignerContext = React.createContext<[Signer | undefined, React.Dispatch<React.SetStateAction<Signer | undefined>>]>([defaultSigner, () => { }]);
const defaultSymfoniContext: SymfoniContextInterface = {
    currentHardhatProvider: "",
    init: () => { throw Error("Symfoni context not initialized") },
    loading: false,
    messages: [],
    providers: []
};
export const SymfoniContext = React.createContext<SymfoniContextInterface>(defaultSymfoniContext);
export const AvaxApesContext = React.createContext<SymfoniAvaxApes>(emptyContract);
export const BridgingBearsContext = React.createContext<SymfoniBridgingBears>(emptyContract);
export const BridgingBearsHalloweenContext = React.createContext<SymfoniBridgingBearsHalloween>(emptyContract);
export const WrappedBridgingBearsContext = React.createContext<SymfoniWrappedBridgingBears>(emptyContract);
export const BabyBearsContext = React.createContext<SymfoniBabyBears>(emptyContract);
export const ERC1155Context = React.createContext<SymfoniERC1155>(emptyContract);
export const ERC721Context = React.createContext<SymfoniERC721>(emptyContract);

export interface SymfoniContextInterface {
    init: (provider?: string) => void;
    loading: boolean;
    messages: string[];
    currentHardhatProvider: string;
    providers: string[];
}

export interface SymfoniProps {
    autoInit?: boolean;
    showLoading?: boolean;
    loadingComponent?: React.ReactNode;
}

export interface SymfoniAvaxApes {
    instance?: AvaxApes;
    factory?: AvaxApes__factory;
}

export interface SymfoniBridgingBears {
    instance?: BridgingBears;
    factory?: BridgingBears__factory;
}

export interface SymfoniBridgingBearsHalloween {
    instance?: BridgingBearsHalloween;
    factory?: BridgingBearsHalloween__factory;
}

export interface SymfoniWrappedBridgingBears {
    instance?: WrappedBridgingBears;
    factory?: WrappedBridgingBears__factory;
}

export interface SymfoniBabyBears {
    instance?: BabyBears;
    factory?: BabyBears__factory;
}

export interface SymfoniERC1155 {
    instance?: ERC1155;
    factory?: ERC1155__factory;
}

export interface SymfoniERC721 {
    instance?: ERC721;
    factory?: ERC721__factory;
}

export const Symfoni: React.FC<SymfoniProps> = ({
    showLoading = true,
    autoInit = true,
    ...props
}) => {
    const [initializeCounter, setInitializeCounter] = useState(0);
    const [currentHardhatProvider, setCurrentHardhatProvider] = useState("");
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState<string[]>([]);
    const [signer, setSigner] = useState<Signer | undefined>(defaultSigner);
    const [provider, setProvider] = useState<providers.Provider | undefined>(defaultProvider);
    const [currentAddress, setCurrentAddress] = useState<string>(defaultCurrentAddress);
    const [fallbackProvider] = useState<string | undefined>(undefined);
    const [providerPriority, setProviderPriority] = useState<string[]>(["web3modal", "hardhat"]);
    const [AvaxApes, setAvaxApes] = useState<SymfoniAvaxApes>(emptyContract);
    const [BridgingBears, setBridgingBears] = useState<SymfoniBridgingBears>(emptyContract);
    const [BridgingBearsHalloween, setBridgingBearsHalloween] = useState<SymfoniBridgingBearsHalloween>(emptyContract);
    const [WrappedBridgingBears, setWrappedBridgingBears] = useState<SymfoniWrappedBridgingBears>(emptyContract);
    const [BabyBears, setBabyBears] = useState<SymfoniBabyBears>(emptyContract);
    const [ERC1155, setERC1155] = useState<SymfoniERC1155>(emptyContract);
    const [ERC721, setERC721] = useState<SymfoniERC721>(emptyContract);
    useEffect(() => {
        if (messages.length > 0)
            console.debug(messages.pop())
    }, [messages])

    const getProvider = async (): Promise<{ provider: providers.Provider, hardhatProviderName: string } | undefined> => {
        let hardhatProviderName = "Not set";
        let _providerPriority = [...providerPriority];
        // Fallback provider
        if (fallbackProvider && autoInit && initializeCounter === 0) {
            if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === null) {
                _providerPriority = _providerPriority.sort((a, b) => {
                    return a === fallbackProvider ? -1 : b === fallbackProvider ? 1 : 0;
                })
            }
        }
        const provider = await _providerPriority.reduce(async (maybeProvider: Promise<providers.Provider | undefined>, providerIdentification) => {
            let foundProvider = await maybeProvider
            if (foundProvider) {
                return Promise.resolve(foundProvider)
            }
            else {
                switch (providerIdentification.toLowerCase()) {
                    case "web3modal":
                        try {
                            const provider = await getWeb3ModalProvider()
                            const web3provider = new ethers.providers.Web3Provider(provider);
                            hardhatProviderName = "web3modal";
                            return Promise.resolve(web3provider)
                        } catch (error) {
                            return Promise.resolve(undefined)
                        }
                    case "hardhat":
                        try {
                            const provider = new ethers.providers.JsonRpcProvider({
                                url: "http://127.0.0.1:8545",
                            });
                            hardhatProviderName = "hardhat";
                            return Promise.resolve(provider)
                        } catch (error) {
                            return Promise.resolve(undefined)
                        } default:
                        return Promise.resolve(undefined)
                }
            }
        }, Promise.resolve(undefined)) // end reduce
        return provider ? { provider, hardhatProviderName } : undefined
    };
    const getSigner = async (_provider: providers.Provider, hardhatProviderName: string): Promise<Signer | undefined> => {
        switch (hardhatProviderName) {
            case "web3modal":
                const web3provider = _provider as ethers.providers.Web3Provider
                return await web3provider.getSigner()
            case "hardhat":
                return ethers.Wallet.fromMnemonic("test test test test test test test test test test test junk").connect(_provider)
            default:
                return undefined
        }
    };
    const getWeb3ModalProvider = async (): Promise<any> => {
        const providerOptions: IProviderOptions = {

        };
        const web3Modal = new Web3Modal({
            // network: "mainnet",
            cacheProvider: false,
            providerOptions, // required
        });
        return await web3Modal.connect();
    };

    useEffect(() => {
        let subscribed = true
        const doAsync = async () => {
            const finish = (text: string) => {
                setLoading(false)
                setMessages(old => [...old, text])
            }
            const finishWithContracts = (text: string) => {
                setAvaxApes(getAvaxApes(_provider, _signer))
                setBridgingBears(getBridgingBears(_provider, _signer))
                setBridgingBearsHalloween(getBridgingBearsHalloween(_provider, _signer))
                setWrappedBridgingBears(getWrappedBridgingBears(_provider, _signer))
                setBabyBears(getBabyBears(_provider, _signer))
                setERC1155(getERC1155(_provider, _signer))
                setERC721(getERC721(_provider, _signer))
                finish(text)
            }
            if (!autoInit && initializeCounter === 0) return finish("Auto init turned off.")
            setLoading(true)
            setMessages(old => [...old, "Initiating Symfoni React"])
            const providerObject = await getProvider() // getProvider can actually return undefined, see issue https://github.com/microsoft/TypeScript/issues/11094

            if (!subscribed || !providerObject) return finish("No provider or signer.")
            const _provider = providerObject.provider
            setProvider(_provider)
            setMessages(old => [...old, "Useing " + providerObject.hardhatProviderName])
            setCurrentHardhatProvider(providerObject.hardhatProviderName)
            const _signer = await getSigner(_provider, providerObject.hardhatProviderName);

            if (!subscribed || !_signer) return finishWithContracts("Provider, without signer.")
            setSigner(_signer)
            setMessages(old => [...old, "Useing signer"])
            const address = await _signer.getAddress()

            if (!subscribed || !address) return finishWithContracts("Provider and signer, without address.")
            setCurrentAddress(address)

            return finishWithContracts("Completed Symfoni context initialization.")
        };
        doAsync();
        return () => { subscribed = false }
    }, [initializeCounter])

    const getAvaxApes = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? AvaxApes__factory.connect(ethers.constants.AddressZero, _signer) : AvaxApes__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniAvaxApes = {
            instance: instance,
            factory: _signer ? new AvaxApes__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getBridgingBears = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = BridgingBearsDeployment.receipt.contractAddress
        const instance = _signer ? BridgingBears__factory.connect(contractAddress, _signer) : BridgingBears__factory.connect(contractAddress, _provider)
        const contract: SymfoniBridgingBears = {
            instance: instance,
            factory: _signer ? new BridgingBears__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getBridgingBearsHalloween = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = BridgingBearsHalloweenDeployment.receipt.contractAddress
        const instance = _signer ? BridgingBearsHalloween__factory.connect(contractAddress, _signer) : BridgingBearsHalloween__factory.connect(contractAddress, _provider)
        const contract: SymfoniBridgingBearsHalloween = {
            instance: instance,
            factory: _signer ? new BridgingBearsHalloween__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getWrappedBridgingBears = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = WrappedBridgingBearsDeployment.receipt.contractAddress
        const instance = _signer ? WrappedBridgingBears__factory.connect(contractAddress, _signer) : WrappedBridgingBears__factory.connect(contractAddress, _provider)
        const contract: SymfoniWrappedBridgingBears = {
            instance: instance,
            factory: _signer ? new WrappedBridgingBears__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getBabyBears = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = BabyBearsDeployment.receipt.contractAddress
        const instance = _signer ? BabyBears__factory.connect(contractAddress, _signer) : BabyBears__factory.connect(contractAddress, _provider)
        const contract: SymfoniBabyBears = {
            instance: instance,
            factory: _signer ? new BabyBears__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getERC1155 = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? ERC1155__factory.connect(ethers.constants.AddressZero, _signer) : ERC1155__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniERC1155 = {
            instance: instance,
            factory: _signer ? new ERC1155__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getERC721 = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? ERC721__factory.connect(ethers.constants.AddressZero, _signer) : ERC721__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniERC721 = {
            instance: instance,
            factory: _signer ? new ERC721__factory(_signer) : undefined,
        }
        return contract
    }
        ;

    const handleInitProvider = (provider?: string) => {
        if (provider) {
            setProviderPriority(old => old.sort((a, b) => {
                return a === provider ? -1 : b === provider ? 1 : 0;
            }))
        }
        setInitializeCounter(initializeCounter + 1)
    }
    return (
        <SymfoniContext.Provider value={{ init: (provider) => handleInitProvider(provider), providers: providerPriority, currentHardhatProvider, loading, messages }}>
            <ProviderContext.Provider value={[provider, setProvider]}>
                <SignerContext.Provider value={[signer, setSigner]}>
                    <CurrentAddressContext.Provider value={[currentAddress, setCurrentAddress]}>
                        <AvaxApesContext.Provider value={AvaxApes}>
                            <BridgingBearsContext.Provider value={BridgingBears}>
                                <BridgingBearsHalloweenContext.Provider value={BridgingBearsHalloween}>
                                    <WrappedBridgingBearsContext.Provider value={WrappedBridgingBears}>
                                        <BabyBearsContext.Provider value={BabyBears}>
                                            <ERC1155Context.Provider value={ERC1155}>
                                                <ERC721Context.Provider value={ERC721}>
                                                    {showLoading && loading ?
                                                        props.loadingComponent
                                                            ? props.loadingComponent
                                                            : <div>
                                                                {messages.map((msg, i) => (
                                                                    <p key={i}>{msg}</p>
                                                                ))}
                                                            </div>
                                                        : props.children
                                                    }
                                                </ERC721Context.Provider >
                                            </ERC1155Context.Provider >
                                        </BabyBearsContext.Provider >
                                    </WrappedBridgingBearsContext.Provider >
                                </BridgingBearsHalloweenContext.Provider >
                            </BridgingBearsContext.Provider >
                        </AvaxApesContext.Provider >
                    </CurrentAddressContext.Provider>
                </SignerContext.Provider>
            </ProviderContext.Provider>
        </SymfoniContext.Provider>
    )

};
