import cs from "classnames";

function AssetCardRow({ title, value, cols, data = { rank: 1 }, name }) {
  function renderIndicators() {
    return Array(cols)
      .fill(0)
      .map((_, i) => (
        <div
          style={{ height: "0.4rem" }}
          className={cs("rounded mx-0.5 w-full", {
            "bg-red": i <= value - 1,
            "bg-bluee": i > value - 1,
          })}
        ></div>
      ));
  }

  return (
    <div className="flex text-black items-center">
      <span
        className={cs("mr-3 text-xs w-14")}
        style={{ fontFamily: "Poppins" }}
      >
        {title}
      </span>
      <div className="flex w-full gap-1 flex-col">
        <span
          className={cs("mr-3 font-semibold text-xs")}
          style={{ fontFamily: "Poppins" }}
        >
          {": "}
          {name}
        </span>
        <div className="flex w-full">{renderIndicators()}</div>
      </div>
    </div>
  );
}

export default AssetCardRow;
