import { useEffect, useState } from "react";
import cs from "classnames";
import { useEVM } from "../hooks/EVMhook";

export default function Wrapper() {
  const [id, setId] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [_error, setShowError] = useState();
  const { wrapContext, address, mintService } = useEVM();
  const [isApprovedWrapped, setIsApprovedWrapped] = useState(null);
  const [isApprovedUnWrapped, setIsApprovedUnWrapped] = useState(null);

  async function handleWrap() {
    setShowError(null);
    try {
      setShowSuccess(false);
      setShowLoading(true);
      const transaction = await wrapContext?.instance?.wrapMulti([id]);
      wrapContext?.instance?.provider
        ?.waitForTransaction(transaction?.hash)
        .then(() => {
          setShowSuccess(true);
          setShowLoading(false);
        });
    } catch (error) {
      if (error?.code === 4001 && error.message) {
        setShowError(error?.message);
      } else if (error?.data?.message) {
        setShowError(error?.data?.message);
      } else {
        setShowError("Something went wrong. Try again.");
      }
      setShowLoading(false);
    }
  }
  async function handleUnwrap() {
    setShowError(null);
    try {
      setShowSuccess(false);
      setShowLoading(true);
      const transaction = await wrapContext?.instance?.unwrap(id);
      wrapContext?.instance?.provider
        ?.waitForTransaction(transaction?.hash)
        .then(() => {
          setShowSuccess(true);
          setShowLoading(false);
        });
    } catch (error) {
      if (error?.code === 4001 && error.message) {
        setShowError(error?.message);
      } else if (error?.data?.message) {
        setShowError(error?.data?.message);
      } else {
        setShowError("Something went wrong. Try again.");
      }
      setShowLoading(false);
    }
  }

  async function approveContract() {
    console.log("hereee")
    try {
      setShowLoading(true);
      const operator = wrapContext?.instance?.address;
      const transaction = await mintService.instance.setApprovalForAll(
        operator,
        true
      );
      await mintService.instance.provider?.waitForTransaction(transaction.hash);
      const isApprovedForAll = await mintService.instance.isApprovedForAll(
        address,
        operator
      );
      setIsApprovedWrapped(isApprovedForAll);
      setShowLoading(false);
    } catch (error) {
      console.log(error)
      if (error?.code === 4001 && error.message) {
        setShowError(error?.message);
      } else if (error?.data?.message) {
        setShowError(error?.data?.message);
      } else {
        setShowError("Something went wrong. Try again.");
      }
      setShowLoading(false);
    }
  }

  async function approveWContract() {
    try {
      setShowLoading(true);
      const operator = wrapContext?.instance?.address;
      const transaction = await wrapContext.instance.setApprovalForAll(
        operator,
        true
      );
      await mintService.instance.provider?.waitForTransaction(transaction.hash);
      const isApprovedForAll = await mintService.instance.isApprovedForAll(
        address,
        operator
      );
      setIsApprovedUnWrapped(isApprovedForAll);
      setShowLoading(false);
    } catch (error) {
      if (error?.code === 4001 && error.message) {
        setShowError(error?.message);
      } else if (error?.data?.message) {
        setShowError(error?.data?.message);
      } else {
        setShowError("Something went wrong. Try again.");
      }
      setShowLoading(false);
    }
  }

  useEffect(() => {
    async function getData() {
      const operator = wrapContext?.instance?.address;
      const isApprovedForAll = await mintService.instance.isApprovedForAll(
        address,
        operator
      );
      setIsApprovedWrapped(isApprovedForAll);
      const isApprovedForAll2 = await wrapContext.instance.isApprovedForAll(
        address,
        operator
      );
      setIsApprovedUnWrapped(isApprovedForAll2);
    }
    if (address && mintService.instance && wrapContext?.instance?.address) {
      getData();
    }
  }, [
    address,
    mintService.instance,
    wrapContext.instance,
    wrapContext.instance?.address,
  ]);

  console.log(isApprovedUnWrapped, isApprovedWrapped);

  return (
    <div className="flex flex-col gap-2">
      <span className="text-lg font-medium">
        {!isApprovedWrapped && !isApprovedUnWrapped
          ? "Enter Id to wrap/unwrap"
          : "Approve tokens to wrap/unwrap"}
      </span>
      <div className="flex flex-col gap-3">
        <input
          disabled={!isApprovedWrapped && !isApprovedUnWrapped}
          onChange={(e) => {
            if (e.target.value < 0) {
              return;
            }
            setId(parseInt(e.target.value));
          }}
          value={id}
          type="number"
          placeholder="Enter NFT Id"
          className="w-32 px-1 text-black rounded"
        />
        <div className="flex gap-2">
          <>
            <button
              onClick={() => {
                isApprovedWrapped ? handleWrap() : approveContract();
              }}
              disabled={!id && isApprovedWrapped}
              className={cs(
                "text-red bg-white rounded text-sm font-medium px-2",
                {
                }
              )}
            >
              {isApprovedWrapped === true ? "Wrap" : "Approve tokens to wrap"}
            </button>
            <button
              onClick={() => {
                isApprovedUnWrapped ? handleUnwrap() : approveWContract();
              }}
              disabled={!id && isApprovedUnWrapped}
              className={cs(
                "text-red bg-white rounded text-sm font-medium px-2",
                {
                }
              )}
            >
              {isApprovedUnWrapped ? "Unwrap" : "Approve tokens to unwrap"}
            </button>
          </>
        </div>
      </div>
      {showLoading && (
        <div className="text-lg animate-pulse mt-10">Loading...</div>
      )}
      {_error && _error}
      {showSuccess && (
        <a href={"https://opensea.io/"+address} className="text-lg mt-10">
          Wrap/Unwrap succeeded! Click here to check your wallet in Open Sea.
        </a>
      )}
    </div>
  );
}
