import { useState, useEffect, useRef } from "react";
import { fetchData } from "../lambda/lambda";
import topImage from "../assets/TopImage.png";
import family from "../assets/family.jpg";
import mintImage from "../assets/MintImage.png";
import gif from "../assets/BBGif.gif";
import rarity from "../assets/Rarity.png";
import { useEVM } from "../hooks/EVMhook";
import AssetCard from "../components/AssetCard";
import Button from "../components/Button";
import CountInput from "./Mint/CountInput";
import Baby from "./Baby";
import Wrapper from "./Wrapper";


// const countURL =
//   "https://gssyg7858c.execute-api.us-east-1.amazonaws.com/totalSupply";
const url =
  "https://gssyg7858c.execute-api.us-east-1.amazonaws.com/listmetadata?indices=";

function Home() {
  const [mintedCount, setMintedCount] = useState(1);
  const ref = useRef([]);
  const [count, setCount] = useState(1);
  const [mintID, setMintID] = useState(null);
  const [mintedIds, setMintedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lockedCount, setLockedCount] = useState(0);
  const [mintedUrls, setMintedUrls] = useState([]);
  const { mintService } = useEVM();
  const [error, setError] = useState(null);

  useEffect(() => {
    mintService.instance?.removeListener("Transfer");
    mintService.instance?.on("Transfer", async (a, b, c, d) => {
      const harmonautIndex = c.toNumber();
      if (loading && mintID === d.transactionHash) {
        ref.current = [...ref.current, harmonautIndex.toString()];
        setMintedIds(ref.current);
      }
    });
    if (mintedIds.length === lockedCount && mintedIds.length !== 0) {
      fetchData(url + mintedIds.join(",")).then((_data) => {
        setMintedUrls(_data);
        setLoading(false);
      });
    }
    mintService?.instance
      ?.totalSupply()
      .then((d) => setMintedCount(d?.toString()));
    // fetchData(countURL).then((d) => setMintedCount(0));
  }, [loading, lockedCount, mintID, mintService.instance, mintedIds]);

  async function handleMint() {
    setError(null);
    try {
      ref.current = [];
      setMintedIds([]);
      setLockedCount(count);
      const price = await mintService.instance?.price();
      const mulPrice = price.mul(count);
      const transaction = await mintService.instance?.mint(count, {
        value: mulPrice,
      });

      setMintedUrls(
        new Array(count).fill(1).map((i, index) => ({ _id: i + index * 1234 }))
      );
      setLoading(true);
      setMintID(transaction.hash);
    } catch (e) {
      setError(e?.data?.message);
    }
  }
  async function handleMintPresale() {
    setError(null);
    try {
      ref.current = [];
      setMintedIds([]);
      setLockedCount(count);
      const price = await mintService.instance?.price();
      const mulPrice = price.mul(count);
      const transaction = await mintService.instance?.mintPresale(count, {
        value: mulPrice,
      });

      setMintedUrls(
        new Array(count).fill(1).map((i, index) => ({ _id: i + index * 1234 }))
      );
      setLoading(true);
      setMintID(transaction.hash);
    } catch (e) {
      setError(e?.data?.message);
    }
  }
  return (
    <div className="w-full pb-14 text-black flex flex-col items-center justify-center">
      <div
        style={{
          backgroundImage: `url(${topImage})`,
          backgroundSize: "cover",
        }}
        className="w-full py-20 flex-1 h-full -mt-14 gap-8 justify-center flex flex-col items-center"
      >
        <div className="h-96 flex flex-col items-cetner justify-center gap-4">
          <span className="text-black p-3 rounded text-center bg-white bg-opacity-50 font-bold text-3xl">
            10,000 Bears are crossing the bridge
            <br /> to roam the AVAX Blockchain
          </span>
        </div>
      </div>
      <div className="bg-red w-full items-center text-white flex-col flex">
        <div className="flex py-14 md:py-28 gap-20 w-full items-center flex-col">
          <div className="flex md:flex-row max-w-7xl w-full flex-col">
            <div className="flex px-6 md:px-16 justify-center flex-col gap-8 flex-1">
              <img src={gif} alt="gif" className="w-full max-w-lg" />
            </div>
            <div className="flex px-6 md:px-16 mt-10 md:mt-0 flex-col gap-8 flex-1">
              <span className="font-bold text-3xl">
                All About Bridging Bears
              </span>
              <span>
                In search of cheap fees and fast transactions the Bridging Bears
                are crossing over to AVAX! Bridging Bears is a collectible
                generative NFT consisting of 10,000 unique bears.
                Programmatically created from 120 possible traits such as hats,
                hair, clothing, and expressions!
              </span>
            </div>
          </div>
        </div>

        <div
        id="mint"
        style={{
          backgroundImage: `url(${mintImage})`,
          backgroundSize: "cover",
        }}
        className="w-full py-24 md:py-48 flex-1 h-full gap-4 justify-center flex flex-col items-center justify-center"
      >
        <div className="flex justify-around items-center gap-10 md:gap-0 md:flex-row flex-col w-full">
          <div className="flex gap-4  flex-col">
            <span className="font-bold text-4xl">{2000}/2,000</span>
            <span className="font-bold  text-4xl">Presale Bears Minted</span>
            <div className="flex gap-0">
              <CountInput onChange={(c) => setCount(c)} />
              <Button
                onClick={handleMintPresale}
                className="font-bold px-9"
                text={loading ? "Loading" : "Mint"}
              />
            </div>
            <span className="text-sm">
              Make sure you mint with a wallet that holds an AVAX Ape
            </span>
          </div>
          <div className="flex gap-4  flex-col">
            <span className="font-bold text-4xl">{mintedCount}/10,000</span>
            <span className="font-bold text-4xl">Bridging Bears Minted</span>
            <div className="flex gap-0">
              <CountInput onChange={(c) => setCount(c)} />
              <Button
                onClick={handleMint}
                className="font-bold px-9"
                text={loading ? "Loading" : "Mint"}
              />
            </div>
          </div>
        </div>
        {error && <span className="p-4 bg-red bg-opacity-30">{error}</span>}
        {mintID && mintedUrls.length > 0 && (
          <div className="flex max-w-6xl flex-col w-full mt-16">
            <span
              style={{ fontFamily: "Poppins" }}
              className="self-center font-semibold text-3xl md:text-4xl"
            >
              Your Bridged Bears
            </span>
            <div className="flex flex-wrap gap-3 mt-3 w-full justify-center">
              {!loading &&
                mintedUrls.map((u, index) => (
                  <div key={u.id} className="w-64">
                    <AssetCard data={u} index={index} />
                  </div>
                ))}
              {loading &&
                mintedUrls.map((u, index) => (
                  <div key={u._id} className="w-64">
                    <AssetCard data={u} index={index} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
        
        <div className="flex flex-col gap-8 py-14 md:py-28 px-6 md:px-24 ">
          <div className="max-w-6xl md:px-10">
            <span className="font-bold mb-4 self-start text-3xl">Rarity</span>
            <div className="flex md:flex-row gap-10 flex-col">
              <div className="flex gap-3 flex-col">
                Entry to cross the bridge only has one requirement, each bear
                must bring a unique look to AVAX. <br />
                <br />
                Each Bridging Bears attributes are individually scored based on
                rarity, backgrounds are determined by the total score of all
                attributes.
                <br />
                <br />
                Crossing the bridge you will see a variety of different bear
                breeds:
                <br />
                <li>
                  <span className="font-semibold">Polar:</span> The most
                  commonly spotted bear on this route
                </li>
                <li>
                  <span className="font-semibold">Ice Age:</span> The rarer
                  ancestor of the polar bear, originating from prehistoric times
                </li>
                <li>
                  <span className="font-semibold">Mutant:</span> Only a few of
                  these bears escaped from an experimental lab in search of
                  freedom on AVAX
                </li>
                <li>
                  <span className="font-semibold">Golden Himalayan:</span> The
                  rarest of all breeds which are rumored to be a creation of the
                  Norse Gods.
                </li>
              </div>
              <img
                style={{ maxHeight: 320 }}
                className="mt-10 md:mt-0 w-full max-w-xl"
                alt="rarity"
                src={rarity}
              />
            </div>
          </div>
        </div>
      </div>
     
      <div className="md:flex-row w-full flex-col flex">
        <div className="flex px-6 md:px-24 bg-red py-14 md:py-28 text-white flex-col gap-8 flex-1">
          <span className="font-bold text-3xl">Wrap Bears</span>
          <span>
            Wrap your Bridging Bears NFS to be able to trade them in any
            decentralized exchange.
            <br />
            You need to unwrap your Bridging Bears NFTS again to claim rewards.
          </span>
          <Wrapper/>
        </div>
        <div className="flex py-14 md:py-28 px-6 md:px-24 flex-col bg-white gap-8 flex-1">
          <span className="font-bold text-3xl">Baby Bears</span>

          <div className="flex gap-4 flex-col">
            <span>
              Each Bridging Bear will only be able to breed one time and
              creating a Baby Bridging Bear requires 2 Bridging Bears.
            </span>
            <img className="w-full" alt="family" src={family} />
              <Baby/>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${rarityImage})`,
          backgroundSize: "cover",
        }}
        className="py-28 w-full text-black flex-col items-center jusitfy-center flex"
      >
      </div> */}
      <div className="md:flex-row w-full flex-col justify-center bg-bluee flex">
        <div className="flex px-6 md:px-24 max-w-7xl py-14 md:py-28 text-black flex-col gap-8 flex-1">
          <span className="font-bold text-4xl">FAQ</span>
          <div className="gap-2 flex flex-col">
            <span className="font-bold text-2xl">
              What benefits does AVAX Apes holders get?
            </span>
            <li>Presale 30 minutes before public opening</li>
            <li>5% reflected back to AVAX Apes Holders</li>
            <li>
              0.5% of each secondary sale royalty distributes to AVAX Apes
              Holders
            </li>
          </div>
          <div className="gap-2 flex flex-col">
            <span className="font-bold text-2xl">
              How much does it cost to mint a Bridging Bear?
            </span>
            <span className="text-xl bg-blue bg-opacity-20 w-28 text-center rounded border border-black ml-6 text-black font-bold">
              1.75 AVAX
            </span>
            <li>10% reflected back to Bear Holders</li>
            <li>5% reflected back to AVAX Apes Holders</li>
          </div>

          <div className="gap-2 flex flex-col">
            <span className="font-bold text-2xl">
              What can I do with my Bridging Bear?
            </span>
            <span className="text-lg">
              You are free to do anything with your Bridging Bear under a
              non-exclusive license. (As long as you own it)
            </span>
          </div>

          <div className="gap-2 flex flex-col">
            <span className="font-bold text-2xl">
              When can I mint a Bridging Bear?
            </span>
            <span className="text-lg">
              We are launching Friday October 22nd @ 4pm UTC
            </span>
          </div>

          <div className="gap-2 flex flex-col">
            <span className="font-bold text-2xl">How do I mint?</span>
            <span className="text-lg">
              To mint you must use metamask on our site on the Avalanche
              Blockchain
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
