import cs from "classnames";

function Button({ text, className, style, onClick, selected, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{ ...style }}
      className={cs(
        "outline-none rounded-full text-white text-bold text-lg rounded bg-red border border-transparent hover:text-white px-5",
        className
      )}
    >
      {text}
    </button>
  );
}

export default Button;
